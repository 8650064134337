import actions from '../modules/actions'
import animate from '../modules/animate'
import carousel from '../modules/carousel'
import gaEvents from '../modules/ga-events'
import popup from '../modules/popup'
import scroller from '../modules/scroller'
import sticky from '../modules/sticky'
import toggle from '../modules/toggle'
import wordChanger from '../modules/word-changer'

const Ui = {
  init(element) {
    toggle.init(element)
    scroller.init(element)
    carousel.init(element)
    wordChanger.init(element)
    sticky.init(element)
    animate.init(element)
    popup.init(element)
    gaEvents.init(element)
    actions.init(element)
  },
}

export default Ui
