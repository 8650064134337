const Animate = {
  defaultThreshold: 0.25,

  options: {
    threshold: [0.025, 0.25, 0.5, 0.75, 0.975],
  },

  init(element = document) {
    const sections = Array.from(element.querySelectorAll('[data-animate]'))

    if ('IntersectionObserver' in window) {
      // Create an intersection observer
      this.observer = new IntersectionObserver(this.onEntry.bind(this), this.options)
    }

    sections.forEach((section) => {
      section.classList.add('animate-init')

      if (!this.observer) {
        section.classList.add('animate-run')
      } else {
        window.addEventListener('load', this.observer.observe(section), false)
      }
    })
  },

  onEntry(entries) {
    entries.forEach((entry) => {
      const section = entry.target
      const viewportHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0,
      )
      const sectionHeight = section.clientHeight

      if (
        entry.intersectionRatio >=
        parseFloat(
          section.dataset.animateThreshold ||
            (sectionHeight > viewportHeight ? 0.025 : this.defaultThreshold),
        )
      ) {
        setTimeout(() => {
          section.classList.add('animate-run')

          if (this.callbacks[section.dataset.animate]) {
            this.callbacks[section.dataset.animate](section)
          }
        }, parseInt(section.dataset.animateDelay || 0, 10))

        // Remove the section observer
        this.observer.unobserve(section)
      }
    })
  },

  callbacks: {},
}

export default Animate
