const GaEvents = {
  init(element = document) {
    if (!window.gtag) {
      return
    }

    const triggers = Array.from(element.querySelectorAll('[data-ga-category]'))

    triggers.forEach((trigger) => {
      trigger.addEventListener(
        trigger.hasAttribute('action') ? 'submit' : 'click',
        this.sendToAnalytics,
      )
    })
  },

  sendToAnalytics(ev, res) {
    const data = ev.currentTarget.dataset

    if ((!res || res.valid) && !data.gaBlocked) {
      console.log('Triggered:', data.gaAction, data.gaCategory, data.gaLabel, data.gaValue)

      window.gtag('event', data.gaAction, {
        event_category: data.gaCategory,
        event_label: data.gaLabel,
        value: data.gaValue,
      })
    }
  },
}

export default GaEvents
