import Flickity from 'flickity'
import 'flickity-imagesloaded'

const Carousel = {
  init(element = document) {
    const carousels = Array.from(element.querySelectorAll('[data-carousel]'))

    carousels.forEach((carousel) => {
      if (carousel.childElementCount > 1) {
        new Flickity(carousel, {
          cellAlign: 'center',
          contain: true,
          wrapAround: true,
          selectedAttraction: 0.02,
          friction: 0.28,
          autoPlay: parseInt(carousel.dataset.carouselAutoplay, 10) || false,
          adaptiveHeight: true,
          imagesLoaded: true,
          pageDots: false,
        })
      }
    })
  },
}

export default Carousel
