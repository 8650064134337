/*!
 ** Project: EnablingInsights
 ** Author: Piotr Kulpinski <piotr@kulpinski.pl>
 ** --------------------------------
 * */

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'

import panels from './utils/panels'
import ui from './utils/ui'

// UI
ui.init()

// Utils
panels.init()

// Polyfills
svg4everybody()
objectFitImages()
