const WordChanger = {
  element: 'em',
  interval: 3000,

  init(element = document) {
    const changers = Array.from(element.querySelectorAll('[data-word-changer]'))

    changers.forEach((changer) => {
      element = changer.querySelector(this.element)
      const interval = changer.dataset.wordChangerSpeed || this.interval

      if (element) {
        const words = changer.dataset.wordChanger.split(', ')

        if (words.length > 1) {
          this.insertWords(element, words)
          this.changeWord(element, interval)
        }
      }
    })
  },

  insertWords(element, words) {
    let template = ''

    words.forEach((word) => {
      template += `<span>${word}</span>`
    })

    element.innerHTML = template
  },

  changeWord(element, interval, currentIndex = 0) {
    const words = element.childNodes
    const currentWord = words[currentIndex]

    words.forEach((word) => {
      word.classList.remove('is-active')
    })

    element.style.width = `${currentWord.getBoundingClientRect().width}px`
    currentWord.classList.add('is-active')

    currentIndex += 1

    if (currentIndex >= words.length) {
      currentIndex = 0
    }

    setTimeout(() => {
      this.changeWord(element, interval, currentIndex)
    }, interval)
  },
}

export default WordChanger
