import axios from 'axios'

import ui from '../utils/ui'

const Popup = {
  clearing: false,

  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-popup]'))
    const autoTriggers = Array.from(element.querySelectorAll('[data-popup-auto]'))

    if (triggers.length) {
      triggers.forEach((trigger) => {
        trigger.removeEventListener('click', this.handlePopup)
        trigger.addEventListener('click', this.handlePopup)
      })
    }

    if (autoTriggers.length) {
      window.addEventListener('load', (ev) => {
        autoTriggers.forEach((trigger) => {
          if (trigger.dataset.popupAuto !== 'once' || !this.getPopupStorage()) {
            this.handlePopup(ev, trigger)
          }
        })
      })
    }

    document.addEventListener('panels:close', this.clearPopup.bind(this))
    document.addEventListener('popup:close', this.clearPopup.bind(this))
  },

  handlePopup(ev, instance = null) {
    ev.preventDefault()

    const trigger = ev.currentTarget
    const target = instance || trigger.dataset.popup

    if (Popup.clearing) {
      return
    }

    if (trigger && target) {
      // Show loader
      document.body.classList.add('is-loading')

      // Close navigation
      document.body.classList.remove('has-nav-open')

      if (target === 'remote') {
        const targetURL = new URL(ev.currentTarget.href)

        // Set remote param
        targetURL.searchParams.set('remote', true)

        axios.get(targetURL).then((response) => Popup.callbacks.insertTemplate(response.data))
      } else {
        const element = document.querySelector(target)

        if (element) {
          Popup.callbacks.insertTemplate(element.innerHTML)
        }
      }
    } else {
      Popup.clearPopup()
    }
  },

  clearPopup() {
    if (Popup.content) {
      Popup.clearing = true

      // Set local storage
      this.setPopupStorage()

      setTimeout(() => {
        Popup.content.innerHTML = ''
        Popup.content.parentNode.classList.remove('has-form')
        Popup.clearing = false
      }, 500)
    }

    // Remove popup class
    document.body.classList.remove('has-popup-open')
  },

  getPopupStorage() {
    return window.localStorage.getItem('is-popup-closed')
  },

  setPopupStorage() {
    window.localStorage.setItem('is-popup-closed', true)
  },

  callbacks: {
    insertTemplate(template) {
      // Set popup content
      Popup.content = document.querySelector('[data-popup-content]')

      // Insert template
      Popup.content.innerHTML = template

      // Reinit UI elements
      ui.init(Popup.content)

      // Add popup class
      document.body.classList.add('has-popup-open')

      // Hide loader
      document.body.classList.remove('is-loading')

      // Check if it contains form
      const form = Popup.content.querySelector('form')

      if (form) {
        Popup.content.parentNode.classList.add('has-form')
      }
    },
  },
}

export default Popup
