const Scroller = {
  triggers: [],

  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-scroll], a[href^="#"]'))

    triggers.forEach((trigger) => trigger.addEventListener('click', this.handleScroll.bind(this)))
  },

  handleScroll(ev) {
    const trigger = ev.currentTarget
    let target = trigger.dataset.scroll || trigger.getAttribute('href')

    if (target && target.length > 1) {
      // Remove everything before the "#"
      target = target.substring(target.indexOf('#'))

      // Scroll to target
      if (this.scrollTo(target)) {
        // Prevent page jumping
        ev.preventDefault()
      }
    }
  },

  scrollTo(target, offset = 0) {
    const element = document.querySelector(target)

    if (element) {
      window.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - offset,
      })

      // Close navigation
      document.body.classList.remove('has-nav-open')
    }

    return element
  },
}

export default Scroller
