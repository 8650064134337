const Toggle = {
  init(element = document) {
    const triggers = Array.from(element.querySelectorAll('[data-toggle]'))

    triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.handleToggle)
    })
  },

  handleToggle(ev) {
    ev.preventDefault()
    document.body.classList.toggle(`has-${ev.currentTarget.dataset.toggle}-open`)
  },
}

export default Toggle
