const Sticky = {
  options: { threshold: [0, 1] },

  init(element = document) {
    if (
      'IntersectionObserver' in window &&
      'IntersectionObserverEntry' in window &&
      'intersectionRatio' in window.IntersectionObserverEntry.prototype
    ) {
      // Create an intersection observers
      this.observeIntersections(element)
    }

    // Watch for sticky-change events
    document.addEventListener('sticky-change', (e) => {
      const { target, state, force } = e.detail

      if (target) {
        target.classList.toggle(state, force)
      }
    })
  },

  observeIntersections(element) {
    const sections = Array.from(element.querySelectorAll('[data-sticky]'))
    const observer = new IntersectionObserver(this.observerCallback.bind(this), this.options)

    sections.forEach((section) => {
      const trigger = document.querySelector(section.dataset.sticky)

      if (trigger) {
        observer.observe(trigger)
      }
    })
  },

  observerCallback(entries) {
    entries.forEach((entry) => {
      const boundingRect = entry.boundingClientRect
      const section = document.querySelector(`[data-sticky="#${entry.target.id}"]`)

      if (section) {
        this.fireEvent(section, 'is-sticky', boundingRect.top < 0)
        this.fireEvent(section, 'is-condensed', boundingRect.top * -1 > boundingRect.height)
      }
    })
  },

  fireEvent(target, state, force) {
    const e = new CustomEvent('sticky-change', {
      detail: { target, state, force },
    })

    document.dispatchEvent(e)
  },
}

export default Sticky
